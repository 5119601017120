/*global pushly*/
import Cookies from 'js-cookie';

const launch = {
    liveintent: () => {
        const liScript = document.createElement('script');
        liScript.setAttribute('type', 'text/javascript');
        liScript.setAttribute('src', '//b-code.liadm.com/a-00cj.min.js');
        liScript.setAttribute('async', 'true');
        liScript.setAttribute('charset', 'utf-8');
        document.getElementsByTagName('head')[0].appendChild(liScript);
    },
    liveramp: () => {
        const liveramp_source = window.appState.cmp.liveramp_source;
        if (liveramp_source) {
            const lrScript = document.createElement('script');
            lrScript.setAttribute('src', liveramp_source);
            document.body.appendChild(lrScript);
        }
    },
    neustar: () => {
        const neustar_source = window.appState.cmp.neustar_source;
        const nsScript1 = document.createElement('script');
        nsScript1.setAttribute('src', 'https://aa.agkn.com/adscores/r.js?sid=9112306668');
        document.body.appendChild(nsScript1);

        if (neustar_source) {
            const nsScript2 = document.createElement('script');
            nsScript2.setAttribute('src', neustar_source);
            document.body.appendChild(nsScript2);
        }
    },
};

let policyInterval = null;
let policyTries = 0;
let uspTries = 0;
let uspInterval = null;

const checkUsp = () => {
    const uspTriesLimit = 4;

    uspTries++;

    if (typeof window.__uspapi === 'function') {
        clearInterval(uspInterval);
        cmpCustomActions();
    } else if (uspTries > uspTriesLimit) {
        clearInterval(uspInterval);
    }
};

window.onload = () => {
    uspInterval = setInterval(checkUsp, 3000);
};

const cmpCustomActions = async () => {
    const [jurisdiction, uspString] = await pingApiJurisdiction();
    const full_width = window.appState.cmp.view == 'full-width';
    if (jurisdiction === 'GDPR') {
        policyModalHide();
        if (!full_width) {
            gdprAdsManager();
        }
    } else if (jurisdiction === 'CCPA') {
        if (!full_width) {
            ccpaAdsManager(uspString);
        }
    } else if (jurisdiction === 'default') {
        if (!full_width) {
            launchThirdParty();
        }
    }
};

const launchThirdParty = () => {
    pushly('confirm_consent'); // allow pushly to prompt for notifications
    launch.liveintent();
    launch.liveramp();
    launch.neustar();
};

const pingApiJurisdiction = async () => {
    let result = 'default';
    let usp;
    await window.__uspapi('getUSPData', 1, (uspData, success) => {
        if (success && uspData.uspString != '1---') {
            result = 'CCPA';
            usp = uspData.uspString;
        } else if (typeof window.__tcfapi === 'function'){
            window.__tcfapi('getTCData', 2, (tcData, tcfSuccess) => {
                if (tcfSuccess) {
                    result = tcData.gdprApplies ? 'GDPR' : 'default';
                }
            });
        }
    });

    return [result, usp];
};

const checkPolicy = () => {
    const el = document.querySelector('policy-consent');
    const policyTriesLimit = 4;

    policyTries++;

    if (policyTries > policyTriesLimit) {
        clearInterval(policyInterval);
    } else if (el) {
        clearInterval(policyInterval);
        el.style.display = 'none';
    }
};

const policyModalHide = () => {
    Cookies.set('POLICY_CONSENT_SHOWN', 1, {
        expires: 365,
        domain: '.tarot.com',
        secure: /^https:/.test(location),
    });

    policyInterval = setInterval(checkPolicy, 1000);
};

const mapPurposes = (tcPData, purposes) => {
    const list = [];

    purposes.forEach((purpose) => {
        list.push(tcPData.consents[purpose] || tcPData.legitimateInterests[purpose]);
    });

    return list.every((item) => {
        return item;
    });
};

const checkPurposes = (tcData, vendorName, vendorId, purposes) => {
    if (
        (tcData.vendor.consents[vendorId] || tcData.vendor.legitimateInterests[vendorId])
        &&
        mapPurposes(tcData.purpose, purposes)
    ) {
        launch[vendorName]();
    }
};

const ccpaAdsManager = (uspString) => {
    if (uspString == '1YNN' || uspString == '1YNY') {
        launchThirdParty();
    }
};

const gdprAdsManager = () => {
    window.__tcfapi('addEventListener', 2, (tcData, listenerSuccess) => {
        if (listenerSuccess) {
            if (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded') {
                // Vendor ID and Purposes: https://vendor-list.consensu.org/v2/vendor-list.json
                checkPurposes(tcData, 'liveintent', 148, [1]);
                checkPurposes(tcData, 'liveramp', 97, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
                checkPurposes(tcData, 'neustar', 468, [1, 3, 7, 8, 9]);
            }
        }
    });
};
